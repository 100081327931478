.react-datepicker__input-container {
  height: 100%;

}

.react-datepicker__input-container input {
  height: 100%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #dfdfdf;
  width: 100%;
}